import * as React from "react"
// import { Link } from 'gatsby';

import Layout from "../components/layout"
import Seo from "../components/seo"


const pageKontakt = () => {
  return ( 
    <Layout>
      <Seo title="Kontakt" />
      <section className="py-5 text-center container">
        <div className="row py-lg-5">
          <div className="col-lg-6 col-md-8 mx-auto">
            <h1 className="fw-light">Kontakt</h1>
            <p className="lead text-muted">Abacus Internet Solutions Spółka Jawna</p>
            <p className="lead text-muted">ul. Kraśnięta 83, 80-177 Gdańsk</p>
            <p className="lead text-muted">NIP: 9570871618, Regon: 192988608</p>
            <h2 className="fw-light">Biuro Obsługi Klienta</h2>            
            <p className="lead text-muted">tel.: <a href="tel:+48587322183">58 732 21 83</a></p>
            <p className="lead text-muted">e-mail: <a href="mailto:biuro@bb-battery.pl">biuro@bb-battery.pl</a></p>          
              
          </div>
        </div>

      </section>
    </Layout>
   );
}
 
export default pageKontakt;
